<template>
  <v-container
    id="dashboard"
    tag="section"
    fluid
  >
    <v-progress-circular
      v-if="loading"
      size="20"
      indeterminate
      color="rgba(0, 0, 0, 0.87)"
    />
    <component
      v-else
      v-bind:is="userDashboard"
    />
  </v-container>
</template>

<script>
import AuthService from '@/services/AuthService';

export default {
  name: 'Home',
  components: {
    DashboardPadrao: () =>
      import('@/components/general/graficos/DashboardPadrao.vue'),
    DashboardBrr: () =>
      import('@/components/general/graficos/DashboardBrr.vue'),
    DashboardBdgd: () =>
      import('@/components/general/graficos/DashboardBdgd.vue')
  },
  data: () => ({
    userInfo: {},
    loading: false
  }),
  mounted() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      this.loading = true;
      AuthService.getUserInfo()
        .then((response) => (this.userInfo = response))
        .finally(() => (this.loading = false));
    },
  },
  computed: {
    userDashboard() {
      if (
        !(
          this.userInfo &&
          this.userInfo.dashboard &&
          this.userInfo.dashboard.codigo
        )
      )
        return 'DashboardPadrao';
      const codigo = this.userInfo.dashboard.codigo.toLowerCase();
      const dashboard = codigo[0].toUpperCase() + codigo.slice(1);
      return `Dashboard${dashboard}`;
    }
  }
};
</script>
